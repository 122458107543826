import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { useAgentProfile } from "../Agents/api/useAgentProfile";
import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "../lib";
import { useGetShiftBlock } from "./api/useGetShiftBlock";
import { ShiftBlockCardSkeleton } from "./components/ShiftBlockCardSkeleton";
import { ShiftBlockDetails } from "./components/ShiftBlockDetails";
import { isShiftBlockBookedByAgentId, transformIntoShiftBlockWithDetails } from "./utils";

export function ShiftBlockDetailsPage() {
  const { shiftBlockId, agentId } = useParams<{ shiftBlockId: string; agentId?: string }>();
  const { data: agent, isSuccess: agentProfileSuccess } = useAgentProfile();

  const {
    data: shiftBlock,
    isLoading: isShiftBlockLoading,
    isSuccess: isShiftBlockSuccess,
    refetch: refetchShiftBlock,
  } = useGetShiftBlock(
    { shiftBlockId, agentId },
    { enabled: isDefined(shiftBlockId) && agentProfileSuccess }
  );

  /**
   * TODO: Transform the shiftBlock data into ShiftBlockWithDetails in useGetShiftBlock query instead of doing it in the component
   * We can use the construct below to achieve this
   * `const response = useGetQuery(...) return { ...response, data: transformedData, }`
   *
   */
  function getShiftBlockDetailsHeader() {
    if (isShiftBlockSuccess && agentProfileSuccess) {
      const shiftBlockWithMappedDetails = transformIntoShiftBlockWithDetails(
        shiftBlock.data,
        shiftBlock.included
      );
      return isShiftBlockBookedByAgentId(shiftBlockWithMappedDetails, agent.userId)
        ? "My Booked Block Details"
        : "Open Block Details";
    }

    return "Open Block Details";
  }

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title={getShiftBlockDetailsHeader()}
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS} />}
        />
      }
    >
      {/**
       * Padding Bottom of 8 is provided so that the "Book This Blocks" which is absolute positioned, does not overshadown the shift list UI
       */}
      <Box sx={{ paddingBottom: 8 }}>
        {isShiftBlockLoading && <ShiftBlockCardSkeleton />}
        {isShiftBlockSuccess && agent && (
          <ShiftBlockDetails
            agent={agent}
            shiftBlock={shiftBlock}
            refetchShiftBlock={refetchShiftBlock}
          />
        )}
      </Box>
    </PageWithHeader>
  );
}
