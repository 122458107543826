import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type Agent } from "../Agents";
import { ShiftBlockFilter } from "./components/ShiftBlockFilter";
import { ShiftBlocks } from "./components/ShiftBlocks";

interface ShiftBlockPageProps {
  agent: Agent;
  facilityId?: string;
}

export function ShiftBlockPage(props: ShiftBlockPageProps) {
  const { agent, facilityId } = props;

  return (
    <Stack sx={{ overflowY: "auto", p: 2 }} flex={1} spacing={2}>
      {agent.licensesData.length > 1 && (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
          <Text>Block for</Text> <ShiftBlockFilter agent={agent} />
        </Stack>
      )}

      <ShiftBlocks agent={agent} facilityId={facilityId} />
    </Stack>
  );
}
